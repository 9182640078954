import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { ACTIVITY_TYPES } from 'state-domains/constants';
import { DataFileGroupResponse, HeaderFileGroupResponse } from 'state-domains/domain/drillhole';

import { buildDataFileGroupUrl, buildHeaderFileGroupUrl } from '../../../network/urls';
import { convertToCamel, sendRequestWithXSRFToken } from '../../../utils';

export const addFileGroup = (
    type: ACTIVITY_TYPES,
    fileGroup: any,
    header: boolean,
): Observable<DataFileGroupResponse | HeaderFileGroupResponse> => {
    const url = header ? buildHeaderFileGroupUrl() : buildDataFileGroupUrl(type);
    return sendRequestWithXSRFToken(url, fileGroup, 'POST').pipe(
        switchMap(({ response }: AjaxResponse<DataFileGroupResponse | HeaderFileGroupResponse>) => {
            const converted = convertToCamel<DataFileGroupResponse | HeaderFileGroupResponse>(
                response,
            );
            return observableOf(converted);
        }),
    );
};
